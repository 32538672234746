<template>
  <v-btn
    icon
    :color="starredVal ? 'orange' : 'secondary lighten-4'"
    :disabled="isLoading"
    :small="small"
    @click.stop="toggleProjectStar()"
  >
    <i
      :class="{ 'fas fa-star': starredVal, 'fad fa-star': !starredVal, 'fa-beat-fade': isLoading }"
      small
    ></i>
  </v-btn>
</template>

<script>
import projectsAPI from "../services/projects-service";
export default {
  name: "project-star",
  data() {
    return {
      isLoading: null,
      starredVal: false,
    };
  },
  props: {
    starred: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.starredVal = this.starred;
  },
  methods: {
    toggleProjectStar() {
      this.isLoading = true;
      if (this.starredVal) {
        //un-star
        projectsAPI
          .unstar(this.projectId)
          .then((resp) => {
            this.starredVal = false;
            this.$emit("update:starred", this.starredVal);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
          });
      } else {
        projectsAPI
          .star(this.projectId)
          .then((resp) => {
            this.starredVal = true;
            this.$emit("update:starred", this.starredVal);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
          });
      }
    },
  },
  watch: {
    starred: {
      handler(newValue, oldValue) {
        this.starredVal = this.starred;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
