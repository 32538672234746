var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.modals.active),callback:function ($$v) {_vm.$set(_vm.modals, "active", $$v)},expression:"modals.active"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold d-flex",attrs:{"small":""}},[_c('i',{staticClass:"fal fa-clone mr-2"}),_vm._v("Clone Project ")]),_c('v-divider'),_c('v-form',{ref:"cloneProjectForm",model:{value:(_vm.modals.valid),callback:function ($$v) {_vm.$set(_vm.modals, "valid", $$v)},expression:"modals.valid"}},[_c('v-card-text',[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"my-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field-alt',{attrs:{"rules":[_vm.allRules.required, _vm.allRules.noWhiteSpaces],"label":"Project Name","placeholder":"Project Name","error-messages":_vm.modals.errorMessage,"loading":_vm.modals.nameChecking,"hide-details":"auto","autofocus":""},on:{"input":function($event){_vm.modals.isNameValid = false;
                  _vm.modals.errorMessage = '';}},model:{value:(_vm.modals.data.name),callback:function ($$v) {_vm.$set(_vm.modals.data, "name", $$v)},expression:"modals.data.name"}}),(!_vm.modals.isNameValid && _vm.modals.errorMessage == '')?_c('span',{staticClass:"blue-grey--text"},[_c('i',{staticClass:"fas fa-circle-info mx-1"}),_vm._v("Please Check Name ")]):(_vm.modals.nameChecking && _vm.modals.errorMessage == '')?_c('span',{staticClass:"indigo--text"},[_c('i',{staticClass:"fas fa-spinner-third fa-spin mx-1"}),_vm._v("Checking Name... ")]):(_vm.modals.errorMessage == '')?_c('span',{staticClass:"green--text"},[_c('i',{staticClass:"fas fa-circle-check mx-1"}),_vm._v("Valid Name ")]):_vm._e()],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-select-alt',{attrs:{"id":"ProjectStage","placeholder":"Project Stage","label":"Project Stage","items":_vm.projectStagesList,"hide-details":"","transparent":"","item-text":"textSingle","item-value":"value"},on:{"change":_vm.stageChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('project-stage',{attrs:{"stage":item.value}})]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('project-stage',{attrs:{"stage":item.value}})]}}]),model:{value:(_vm.modals.data.stage),callback:function ($$v) {_vm.$set(_vm.modals.data, "stage", $$v)},expression:"modals.data.stage"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-select-alt',{attrs:{"rules":[_vm.allRules.required],"label":"Project Status","id":"ProjectStatus","placeholder":"Project Status","items":_vm.projectStatusList,"dense":"","filled":"","item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('project-status',{attrs:{"status":item.value}})]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('project-status',{attrs:{"status":item.value}})]}}]),model:{value:(_vm.modals.data.status),callback:function ($$v) {_vm.$set(_vm.modals.data, "status", $$v)},expression:"modals.data.status"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cloneProjectDiscard()}}},[_c('i',{staticClass:"fal fa-xmark mr-2"}),_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"indigo","dark":!_vm.modals.nameChecking && !_vm.modals.isNameValid,"disabled":_vm.modals.nameChecking || _vm.modals.isNameValid},on:{"click":function($event){return _vm.checkIfNameExist()}}},[(_vm.modals.nameChecking)?_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-2"}):_c('i',{staticClass:"fal fa-question mr-2"}),(_vm.modals.nameChecking)?_c('span',[_vm._v("Checking Name...")]):_c('span',[_vm._v("Check Name")])]),_c('v-btn',{attrs:{"color":"info","disabled":!_vm.modals.valid || _vm.modals.cloning || !_vm.modals.isNameValid},on:{"click":function($event){return _vm.cloneProjectConfirmed()}}},[(_vm.modals.cloning)?_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-2"}):_c('i',{staticClass:"fal fa-check mr-2"}),(_vm.modals.cloning)?_c('span',[_vm._v("Cloning...")]):_c('span',[_vm._v("Clone")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }