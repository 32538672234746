<template>
  <div>
    <v-tooltip bottom nudge-bottom="-5">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn height="32" color="white" small @click="generate()">
            <i class="fas fa-file-spreadsheet" style="font-size: 16px"></i>
          </v-btn>
        </div>
      </template>
      <span>Export Projects</span>
    </v-tooltip>
    <slideout dock="right" size="950px" :visible.sync="active" @close="onSlideoutClosing">
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i>
          Concordia Projects Export
        </h3>
        <v-btn @click="discardGenerate()" icon><i class="far fa-times"></i></v-btn>
      </template>
      <v-container class="px-6 pt-3 export-projects-content">
        <v-form v-model="valid" ref="updateForm">
          <div class="d-flex align-center mb-3">
            <v-row class="mt-0" no-gutters v-if="selectedReport">
              <v-col cols="auto">
                <div class="report-title-icon">
                  <i :class="selectedReport.icon"></i>
                </div>
              </v-col>
              <v-col>
                <h3 class="pa-1 ml-2">
                  <b>{{ selectedReport.text }}</b> Report
                  <br />
                  <span class="text--secondary font-weight-medium"
                    >"{{ selectedReport.fullText }}"</span
                  >
                </h3>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <div v-show="!isLoading && !reportGenerated">
            <h3 class="font-weight-bold mt-4">
              <i class="fad fa-filters mr-2"></i> Filter options
            </h3>
            <v-row class="mt-0 mb-2">
              <v-col cols="12" md="6">
                <v-select-alt
                  ref="projectStage"
                  label="Project Stage"
                  placeholder="Project Stage"
                  v-model="options.stages"
                  :items="projectStagesList"
                  dense
                  multiple
                  deletable-chips
                  chips
                  filled
                  hide-details
                  clearable
                  item-text="textSingle"
                  item-value="value"
                >
                  <template v-slot:selection="{ item }">
                    <project-stage
                      class="project-stage-pointer"
                      :stage="item.value"
                    ></project-stage>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-simple-checkbox
                      :value="attrs.inputValue"
                      v-on="on"
                      :ripple="false"
                      class="mr-2"
                    >
                    </v-simple-checkbox>
                    <project-stage
                      class="project-stage-pointer"
                      :stage="item.value"
                    ></project-stage>
                  </template>
                </v-select-alt>
              </v-col>
              <v-col cols="12" md="6">
                <v-select-alt
                  :label="
                    options.stages.length != 1
                      ? `Project Status <span class='text--disabled fs-14px'>(Choose One Stage Only!)</span>`
                      : 'Project Status'
                  "
                  placeholder="Project Status"
                  v-model="options.status"
                  :items="activeStageStatusList"
                  dense
                  filled
                  hide-details
                  clearable
                  item-text="text"
                  item-value="value"
                  :disabled="options.stages.length != 1"
                >
                  <template v-slot:selection="{ item }">
                    <lead-status v-if="isActiveStageLead" :status="item.value"></lead-status>
                    <project-status v-else :status="item.value"></project-status>
                  </template>
                  <template v-slot:item="{ item }">
                    <lead-status v-if="isActiveStageLead" :status="item.value"></lead-status>
                    <project-status v-else :status="item.value"></project-status>
                  </template>
                </v-select-alt>
              </v-col>
              <v-col cols="12" md="6">
                <v-select-alt
                  label="Project Type"
                  id="ProjectType"
                  placeholder="Project Type"
                  v-model="options.type"
                  :items="projectTypesList"
                  hide-details
                  clearable
                  dense
                  filled
                >
                  <template v-slot:selection="{ item }">
                    <span>
                      <b class="blue-grey white--text rounded px-2 mr-1">{{ item.letter }}</b>
                      {{ item.text }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>
                      <b class="blue-grey white--text rounded px-2 mr-1">{{ item.letter }}</b>
                      {{ item.text }}
                    </span>
                  </template>
                </v-select-alt>
              </v-col>
              <v-col cols="12" md="6">
                <client-selector
                  v-model="options.clientId"
                  :allowAdd="false"
                  hide-details
                ></client-selector>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="fromCreatedDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="comp-wrpr align-end">
                      <v-text-field-alt
                        v-model="options.fromCreatedDate"
                        label="Created From"
                        placeholder="Created From"
                        hide-details
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field-alt>
                      <v-btn
                        :disabled="options.fromCreatedDate == null"
                        min-width="32px !important"
                        width="32px !important"
                        height="32px !important"
                        color="secondary"
                        class="pa-0"
                        small
                        @click="options.fromCreatedDate = null"
                      >
                        <i class="far fa-times fs-12px"></i>
                      </v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    v-model="options.fromCreatedDate"
                    @input="fromCreatedDateMenu = false"
                    show-current
                    show-week
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="toCreatedDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="comp-wrpr align-end">
                      <v-text-field-alt
                        v-model="options.toCreatedDate"
                        label="Created Till"
                        placeholder="Created Till"
                        hide-details
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field-alt>
                      <v-btn
                        :disabled="options.toCreatedDate == null"
                        min-width="32px !important"
                        width="32px !important"
                        height="32px !important"
                        color="secondary"
                        class="pa-0"
                        small
                        @click="options.toCreatedDate = null"
                      >
                        <i class="far fa-times fs-12px"></i>
                      </v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    v-model="options.toCreatedDate"
                    @input="toCreatedDateMenu = false"
                    show-current
                    show-week
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h3 class="font-weight-bold mt-4">
              <i class="fad fa-arrow-down-small-big mr-2"></i> Sort options
            </h3>
            <v-row class="mt-0 mb-2">
              <v-col cols="12" md="6">
                <v-select-alt
                  label="Sort By"
                  placeholder="Sort By"
                  ref="sortByExport"
                  v-model="options.sortBy"
                  :items="sortByOptions"
                  dense
                  filled
                  hide-details
                  item-text="text"
                  item-value="value"
                >
                </v-select-alt>
              </v-col>
              <v-col cols="12" md="6">
                <label class="v-fake-label mb-0">Sort Order</label>
                <v-radio-group
                  v-model="options.sortDesc"
                  row
                  color="info"
                  class="mt-2"
                  hide-details
                >
                  <v-radio :value="false">
                    <template v-slot:label>
                      <label class="v-switch-label">Ascending</label>
                    </template>
                  </v-radio>
                  <v-radio :value="true">
                    <template v-slot:label>
                      <label class="v-switch-label">Descending</label>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <div v-if="isLoading && !reportGenerated" class="text-center py-5">
            <video width="320" muted loop autoplay style="padding: 0.5rem; opacity: 0.64">
              <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Preparing Report...</p>
          </div>
          <div
            v-if="!isLoading && reportGenerated"
            class="text-center py-7 mt-3 d-flex justify-center align-center flex-column"
          >
            <div class="report-file-icon xlsx"><i class="fad fa-file-excel"></i></div>
            <h3 class="report-result mt-2">Report Generated</h3>
            <h3 class="report-name mt-2 text--secondary">{{ reportFileName }}</h3>
            <v-btn
              text
              color="info"
              class="report-download-link mt-2"
              :href="reportUrl"
              :download="reportFileName"
            >
              <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
            </v-btn>
          </div>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discardGenerate()" v-if="reportGenerated">
            <i class="fal fa-chevron-left mr-2"></i>CLOSE
          </v-btn>
          <v-btn @click="discardGenerate()" v-else>
            <i class="fal fa-chevron-left mr-2"></i>CANCEL
          </v-btn>
          <v-btn
            color="info"
            :disabled="isLoading || reportGenerated || !valid"
            @click="generateConfirmed()"
            :loading="isLoading"
          >
            <i class="fal fa-sync mr-2"></i> Generate
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
import projectService from "../services/projects-service";
import ProjectStatus from "../components/ProjectStatus.vue";
import ProjectStage from "../components/ProjectStage.vue";
import ClientSelector from "../../Shared/components/ClientSelector.vue";
import LeadStatus from "../../Leads/components/LeadStatus.vue";
import { eventBus } from "../../../main";

export default {
  name: "export-projects",
  data() {
    return {
      Enums: Enums,
      toCreatedDateMenu: false,
      fromCreatedDateMenu: false,
      valid: false,
      active: false,
      isLoading: false,
      reportGenerated: false,
      options: {
        stage: null,
        stages: [],
        status: null,
        clientId: null,
        type: null,
        createdById: null,
        updatedById: null,
        fromCreatedDate: null,
        toCreatedDate: null,
        sortBy: "UpdateDate",
        sortDesc: true,
      },
      selectedReport: {
        text: "Projects List",
        export: "xlsx",
        fullText: "A list of all or subset of concordia projects",
        icon: "fad fa-file-spreadsheet",
      },
      sortByOptions: [
        { text: "Name", value: "Name" },
        { text: "Stage", value: "Stage" },
        { text: "Type", value: "Type" },
        { text: "Number", value: "Number" },
        { text: "Project Complexity", value: "ProjectComplexity" },
        { text: "Installation Factor", value: "InstallationFactor" },
        { text: "Created Date", value: "CreateDate" },
        { text: "Updated Date", value: "UpdateDate" },
      ],
      reportFileName: null,
      reportFileExt: null,
      reportUrl: null,
    };
  },
  methods: {
    generate() {
      this.active = true;
      setTimeout(() => {
        this.$refs.projectStage.focus();
      }, 350);
    },
    generateConfirmed() {
      this.isLoading = true;
      this.$log("generateConfirmed >> this.options", this.options);
      const optionsToSend = this.$clean(this.options, true);
      projectService.exportWithDate(optionsToSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log(">>> projectService export", resp, resp.data.type, resp.headers["x-file-name"]);
      if (resp.data instanceof Blob) {
        let url = URL.createObjectURL(
          new Blob([resp.data], {
            type: resp.data.type,
          })
        );
        this.$log(">>> projectService export", url);
        this.reportUrl = url;
        this.reportFileName = resp.headers["x-file-name"];
        this.reportFileExt = resp.headers["x-file-name"].split(".").pop();
        this.isLoading = false;
        this.reportGenerated = true;
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = resp.headers["x-file-name"];
        // link.click();
        // link.remove();
      } else {
        this.$log("report generate resp.data", resp.data);
        this.$notify(resp.data, "info");
        eventBus.$emit("add-to-notification-center", resp.data);
        this.discardGenerate();
      }
    },
    onCatch(err) {
      this.$log(">>> projectService export", err);
      this.isLoading = false;
      this.reportGenerated = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
    discardGenerate() {
      this.active = false;
      this.isLoading = false;
      this.reportGenerated = false;
      this.reportUrl = null;
      this.reportFileName = null;
      this.reportFileExt = null;
    },
    focus() {
      this.$refs.firstReportBtn.$el.focus();
    },
    onSlideoutClosing() {
      this.discardGenerate();
    },
  },
  computed: {
    isActiveStageLead() {
      if (this.options.stages.length == 1 && this.options.stages[0] == -1) {
        return true;
      }
      return false;
    },
    activeStage() {
      if (this.options.stages.length == 1) {
        return this.getEnumMember(Enums.PROJECT_STAGE_LIST_WITH_LEAD, this.options.stages[0]);
      }
      return null;
    },
    activeStageStatusList() {
      if (!this.activeStage) return [];
      return this.activeStage.PROJECT_STATUS;
    },
    projectStagesList() {
      return Enums.PROJECT_STAGE_LIST_WITH_LEAD;
    },
    projectTypesList() {
      return Enums.PROJECT_TYPE_LIST;
    },
  },
  watch: {
    "options.stages": {
      handler() {
        this.options.status = null;
        // if (this.options.stages.length != 1) {
        //   this.options.status = null;
        // }
      },
      deep: true,
    },
  },
  components: { ProjectStage, ProjectStatus, ClientSelector, LeadStatus },
};
</script>

<style lang="scss">
.export-projects-content {
  .v-data-table-header {
    th:first-child .v-icon.mdi-minus-box,
    th:first-child .v-icon.mdi-checkbox-marked {
      color: $primary-base !important;
    }
  }

  .v-data-table__selected {
    background: rgba($primary-base, 0.1) !important;

    .theme--light.v-icon {
      color: $primary-base !important;
    }

    .v-input--selection-controls__ripple {
      color: $primary-base !important;
    }
  }

  .report-file-icon {
    width: 120px;
    height: 120px;
    border-radius: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 54px;
    overflow: hidden;
    transition: all 0.3s ease-out;

    &.xlsx {
      // background: rgba(#2b8c59, 0.06);
      // color: #2b8c59;
      background: rgba(#0d6e37, 0.06);
      color: #0d6e37;
    }
    &.docx {
      background: rgba(#134bb0, 0.06);
      color: #134bb0;
    }
  }

  .report-title-icon {
    width: 54px;
    height: 54px;
    border-radius: 54px !important;
    background: rgba($shades-black, 0.06);
    color: rgba($shades-black, 0.87);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .report-card {
    overflow: hidden;
    transition: all 0.3s ease-out;
    border: 1.5px solid white;

    .report-icon {
      width: 75px;
      height: 75px;
      margin: 0 auto;
      margin-top: 1rem;
      border-radius: 75px !important;
      background: rgba($shades-black, 0.06);
      color: rgba($shades-black, 0.87);
      border: 1px dashed rgba($shades-black, 0) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      overflow: hidden;
      transition: all 0.3s ease-out;
    }

    .report-icon-ghost {
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 10rem;
      opacity: 0.06;
      user-select: none;
      pointer-events: none;
      z-index: 0;
      transition: all 0.3s ease-out;
    }

    .v-card__title,
    .v-card__subtitle {
      transition: all 0.3s ease-out;
    }

    &:focus-within,
    &.report-card-hovering {
      border-color: rgba($info-base, 0.54) !important;
      // background: var(--v-primary-base) !important;
      // color: #fff;

      // .v-card__title,
      // .v-card__subtitle {
      //   color: #fff;
      // }

      .report-icon {
        // background: rgba(#fff, 0.08);
        background: rgba($info-base, 0.08) !important;
        border-color: rgba($info-base, 0.54) !important;
        color: rgba($info-base, 1);
      }

      .report-icon-ghost {
        font-size: 14rem;
        opacity: 0.07;
        color: rgba($info-base, 1);
        // color: #fff;
      }
    }
  }

  .reports-spaces-search .v-text-field__details {
    display: none !important;
  }
}
</style>
