<template>
  <!-- Create Area Group Modal -->
  <v-dialog v-model="modals.active" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Project
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneProjectForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.noWhiteSpaces]"
                  label="Project Name"
                  placeholder="Project Name"
                  v-model="modals.data.name"
                  :error-messages="modals.errorMessage"
                  :loading="modals.nameChecking"
                  hide-details="auto"
                  autofocus
                  @input="
                    modals.isNameValid = false;
                    modals.errorMessage = '';
                  "
                >
                </v-text-field-alt>
                <span
                  v-if="!modals.isNameValid && modals.errorMessage == ''"
                  class="blue-grey--text"
                >
                  <i class="fas fa-circle-info mx-1"></i>Please Check Name
                </span>
                <span
                  v-else-if="modals.nameChecking && modals.errorMessage == ''"
                  class="indigo--text"
                >
                  <i class="fas fa-spinner-third fa-spin mx-1"></i>Checking
                  Name...
                </span>
                <span v-else-if="modals.errorMessage == ''" class="green--text">
                  <i class="fas fa-circle-check mx-1"></i>Valid Name
                </span>
              </v-col>
              <v-col sm="12">
                <v-select-alt
                  id="ProjectStage"
                  placeholder="Project Stage"
                  label="Project Stage"
                  v-model="modals.data.stage"
                  :items="projectStagesList"
                  hide-details
                  transparent
                  item-text="textSingle"
                  item-value="value"
                  @change="stageChange"
                >
                  <template v-slot:selection="{ item }">
                    <project-stage :stage="item.value"></project-stage>
                  </template>
                  <template v-slot:item="{ item }">
                    <project-stage :stage="item.value"></project-stage>
                  </template>
                </v-select-alt>
              </v-col>
              <v-col sm="12">
                <v-select-alt
                  :rules="[allRules.required]"
                  label="Project Status"
                  id="ProjectStatus"
                  placeholder="Project Status"
                  v-model="modals.data.status"
                  :items="projectStatusList"
                  dense
                  filled
                  item-text="text"
                  item-value="value"
                >
                  <template v-slot:selection="{ item }">
                    <project-status :status="item.value"></project-status>
                  </template>
                  <template v-slot:item="{ item }">
                    <project-status :status="item.value"></project-status>
                  </template>
                </v-select-alt>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cloneProjectDiscard()">
            <i class="fal fa-xmark mr-2"></i>Cancel
          </v-btn>
          <v-btn
            color="indigo"
            :dark="!modals.nameChecking && !modals.isNameValid"
            :disabled="modals.nameChecking || modals.isNameValid"
            @click="checkIfNameExist()"
          >
            <i
              class="fal fa-spinner-third fa-spin mr-2"
              v-if="modals.nameChecking"
            ></i>
            <i v-else class="fal fa-question mr-2"></i>
            <span v-if="modals.nameChecking">Checking Name...</span>
            <span v-else>Check Name</span>
          </v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.cloning || !modals.isNameValid"
            @click="cloneProjectConfirmed()"
          >
            <i
              class="fal fa-spinner-third fa-spin mr-2"
              v-if="modals.cloning"
            ></i>
            <i v-else class="fal fa-check mr-2"></i>
            <span v-if="modals.cloning">Cloning...</span>
            <span v-else>Clone</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import projectsService from "../services/projects-service";
import ProjectStage from "./ProjectStage.vue";
import ProjectStatus from "./ProjectStatus.vue";
import enums from "../../../plugins/enums";

export default {
  components: { ProjectStage, ProjectStatus },
  props: {},
  data() {
    return {
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        cloning: false,
        nameChecking: false,
        isNameValid: false,
        data: {
          name: null,
          projectId: null,
          stage: null,
          status: null
        }
      }
    };
  },
  computed: {
    projectStage() {
      return this.getEnumMember(enums.PROJECT_STAGE, this.modals.data.stage);
    },
    projectStatusList() {
      if (!this.projectStage) return [];
      return this.projectStage.PROJECT_STATUS;
    },
    projectStagesList() {
      return enums.PROJECT_STAGE_LIST;
    }
  },
  methods: {
    stageChange() {
      this.modals.data.status = null;
    },
    open(project) {
      this.modals.active = true;
      this.modals.data.projectId = project.id;
      this.modals.data.stage = project.stage;
      this.modals.data.status = project.status;
      this.modals.data.name = this.projectNameConvention(project.name);
    },
    projectNameConvention(name) {
      return name + " [Clone]";
    },
    cloneProjectDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        cloning: false,
        nameChecking: false,
        data: {
          name: null,
          projectId: null,
          stage: null,
          status: null
        }
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name) {
        this.modals.nameChecking = true;
        projectsService
          .checkIfProjectNameExist(this.modals.data.name)
          .then(resp => {
            if (resp.data) {
              this.modals.isNameValid = false;
              this.modals.errorMessage = `"${this.modals.data.name}" Project name already exists!`;
            } else {
              this.modals.isNameValid = true;
              this.modals.errorMessage = "";
            }
          })
          .finally(() => {
            this.modals.nameChecking = false;
          });
      }
    },
    cloneProjectConfirmed() {
      this.modals.cloning = true;
      projectsService
        .cloneProject(this.modals.data)
        .then(res => {
          this.$dialog.notify.success("project cloned successfully", {
            position: "top-right",
            timeout: 3000
          });
          this.$router.replace({
            name: `view-project`,
            params: { id: res.data.id }
          });
          this.cloneProjectDiscard();
        })
        .catch(err => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.cloning = false));
    }
  }
};
</script>
